import { BToast } from "bootstrap-vue";

export const toaster = {
  make(message, variant, title, autoHideDelay, position = 'top-left') {
    if (message) {
      const me = new BToast()
      const options = {
        solid: true,
        toaster: 'b-toaster-' + position,
      }
      if (variant) options.variant = variant.toLowerCase().trim()
      if (autoHideDelay) options.autoHideDelay = autoHideDelay
      title ? options.title = title : options.noCloseButton = true
      me.$bvToast.toast(message, options);
    } else console.error('Please include a message')
  }
}
